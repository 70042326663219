@import "src/client_customizations/scss/client_master.scss"; 


$CalcPanels-font-size: 14px; 
$CalcPanels-nav-button-font-size: 16px;
.CalcPanels {
	font-size: $CalcPanels-font-size;
	.nav-fill {
		display: flex;
		button {
			flex-grow: 1;
		}
	}
	.nav {
		button {
			font-size: $CalcPanels-nav-button-font-size;
			color: $CalcPanels-nav-button-font-color;
			border: 0;
			background-color: $CalcPanels-nav-button-background-color;
			display: block;
			padding: 0.5rem 1rem;
		}
		button.active {
			background-color: $CalcPanels-nav-button-background-color_active;
			border-color: $CalcPanels-nav-button-border_color_active;
			color: $CalcPanels-nav-button-font-color_active;
		}
	}
	.tab-pane {
		.table {
			text-align: center;
			th {
				&:first-child {
					width: 20%;
				}
				&:not(:nth-last-child) {
					width: auto;
				}
				&:last-child {
					width: 35%;
				}
			}
			td {
				&:first-child {
					text-align: left;
				}
				&:last-child {
					text-align: left;
				}
			}
			tfoot {
				th {
					&:first-child {
						text-align: left;
					}
				}
			}
		}
	}
}
