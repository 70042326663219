@font-face {
  font-family: proxima-nova;
  src: url("./../assets/fonts/proxima/ProximaNovaRegular.otf")
    format("opentype");
}

@font-face {
  font-family: proxima-nova;
  font-weight: 900;
  font-style: italic;
  src: url("./../assets/fonts/proxima/ProximaNovaBlackItalic.otf")
    format("opentype");
}

@font-face {
  font-family: proxima-nova;
  font-weight: 500;
  src: url("./../assets/fonts/proxima/ProximaNovaBold.otf")
    format("opentype");
}

@font-face {
  font-family: proxima-nova;
  font-weight: 500;
  font-style: italic;
  src: url("./../assets/fonts/proxima/ProximaNovaBoldItalic.otf")
    format("opentype");
}
