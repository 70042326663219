@import "src/client_customizations/scss/client_master.scss"; 

.EVCard p {
  width: 100%;
  margin-bottom: 0;
}

.EVCardTop, .EVCardBottom > * {
  padding: 8px;
}

.EVCardTop {
  position: relative;

  /* For image in top-right corner */

  img {
    padding: 0;
  }

  .badge-locally-available, .badge-fuel-type {
    position: absolute;
    display: block;
  }

  .badge-locally-available img {
    width: 21px;
    padding: 0;
  }

  .badge-fuel-type {
    img {
      width: 21px;
      padding: 0;
    }

    right: 8px;
    top: 8px;
  }

  .badge-locally-available {
    right: 28px;
    top: 8px;
  }
}

.EVCardBottom {
  border-top: $EVCard-EVCardBottom-border;
  display: flex;
  flex-wrap: nowrap;
  > * {
    flex-grow: 1;
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .EVCardBottomMatch {
    width: 100px;
  }
  div:first-child {
    border-right: $EVCard-EVCardBottom-border;
  }
}

.EVCardBottomWithLink {
  text-align: center;
  padding: 0 0.75rem 1rem 0.75rem;

  .btn {
    font-size: 0.85rem;
    padding: 0.5rem;
  }
}

@media (min-width: 576px) {
  .EVCardTop {
    padding: 15px 15px 10px 15px;
  }

  .EVCardBottom > * {
    padding: 10px 15px;
  }

  .EVCardTop {
    .badge-fuel-type {
      right: 15px;
      top: 15px;
    }

    .badge-locally-available {
      right: 35px;
      top: 15px;
    }
  }
}