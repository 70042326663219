@import "src/client_customizations/scss/client_master.scss";
.Footer {
	background-color: $GenericFooter-background-color;
	padding-top: 20px;
	color: $GenericFooter-color;
	.h3 {
		margin-bottom: 5px;
		font-size: $GenericFooter-heading-font-size;
		color: $GenericFooter-heading-color;
	}
	.social-icons{
		a {
			height: 23px;
			width: 23px;
			fill: #ffffff;
			margin-right: 10px;
		}
	}
	a {
		transition: 0.3s all ease;
		-webkit-transition: 0.3s all ease;
		font-size: $GenericFooter-links-font-size;
		line-height: $GenericFooter-links-line-height;
		color: $GenericFooter-links-color;
		text-transform: uppercase;
		font-weight: 500;
		text-align: center;
		letter-spacing: 0.6px;
		display: inline-block;
		&:hover {
			color: $GenericFooter-links-hover-color;
			padding-left: 5px;
			text-decoration: none;
		}
	}
	p {
		color: $GenericFooter-heading-color;
		font-size: $GenericFooter-links-font-size;
		font-weight: 400;
	}
	.small {
		font-weight: 400;
	}
	.p {
		color: $GenericFooter-links-color;
	}
}

.disclaimer_container {
	font-family: Arial, Helvetica;
	display: flex;
	align-items: center;
	margin-left: '5px' ;
	font-size: 10px; /* Set the font size to 10px */
	div {
		   background-color:transparent !important;

		   img {
				  max-width: 50%;
				  margin:0 0 25px;
		   }

		   p {
				  color:white;
		   }
	}
}
.footer-big-promise {
	font-size: 1.8rem;
	line-height: 2rem;
	text-transform: uppercase;
	font-weight: 700;
	padding-top: 4rem;
	max-width: 420px;
	text-align: center;
	margin: 0 auto;
}

@media (min-width: 768px) {
	.footer-big-promise {
	  max-width: 661px;
	}
  }
