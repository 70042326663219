@import '../../scss/client_master.scss';

$InputPanel-border: 1px solid #ccc !default;
$InputPanel-border-top: none !default;
$InputPanel-border-bottom: none !default;
$InputPanel-border-left: none !default;
$InputPanel-border-right: none !default;
$InputPanel-padding: 1em !default;
$InputPanel-box-shadow: 0px 20px 41px 0px rgba(0,0,0, 0.1) !default;
$InputPanel-background-color: #fff !default;
$InputPanel-heading-margin-bottom: 0 !default; 
$InputPanel-heading-title-font-size: 16px !default;
$InputPanel-heading-title-font-weight: 600 !default;
$InputPanel-heading-title-font-family: Open Sans !default;
$InputPanel-border-radius: 0px !default;

.InputPanel {
  background-color: $InputPanel-background-color;
  border: $InputPanel-border;
  border-top: $InputPanel-border-top;
  border-bottom: $InputPanel-border-bottom;
  border-left: $InputPanel-border-left;
  border-right: $InputPanel-border-right;
  border-radius: $InputPanel-border-radius;

  padding: $InputPanel-padding;

  box-shadow: $InputPanel-box-shadow;

  .heading {
    display: flex;
    align-items: center;
    margin-bottom: $InputPanel-heading-margin-bottom;

    .title {
      flex-grow: 1;
      font-size: $InputPanel-heading-title-font-size;
      font-weight: $InputPanel-heading-title-font-weight;
      font-family: $InputPanel-heading-title-font-family;
    }

    .btn-link {
      font-size: 22px;
      padding-bottom: 10px;
      color: #212529;

      &:hover,
      &:focus {
        text-decoration: none;
      }
    }
  }

  label {
    text-transform: uppercase; 
    font-weight: 600;
    font-size: 12px;
  }
}
