@import "src/client_customizations/scss/client_master.scss";

section.container.homepage-jumbotron {
	display: flex;
	align-items: center;
}
.homepage-jumbotron {
	.big-promise {
		padding: $HomePage-big-promise-padding;
		background-color: $HomePage-big-promise-background-color;
		color: $HomePage-big-promise-color;
		text-shadow: $HomePage-big-promise-text-shadow;
		min-height: $HomePage-big-promise-min-height;
		width: 100%;
	}
}
#link-card-buttons {
	margin-top: -7rem;
	padding: 3rem 1rem;
	background: transparent;
}
#why-choose-evs {
	padding: 0rem 1rem;
}
.big-promise-caption {
	top: 0;
	left: 0;
	position: absolute;
	padding: 1.5rem 1rem;
}

.big-promise-caption p:first-child {
	max-width: 16rem;
	font-weight: bold;
	color: #ff9900;
	text-transform: uppercase;
	font-size: 1.75rem;
	line-height: 1.75rem;
}
  
.big-promise-caption p:last-child {
	max-width: 12rem;
	font-weight: bold;
	font-size: 19px;
}

.gradient-top-left {
	width: 100%;
	height: 100%;
	position: absolute;
	box-sizing: inherit;
	background: rgba(35, 35, 35, 0.3);
  
	background: -moz-linear-gradient(
	  -40deg,
	  rgba(255, 255, 255, 0.9) 44%,
	  rgba(255, 255, 255, 0.8) 27%,
	  rgba(255, 255, 255, 0) 27.1%,
	  rgba(255, 255, 255, 0) 100%
	);
	background: -webkit-linear-gradient(
	  -40deg,
	  rgba(255, 255, 255, 0.8) 44%,
	  rgba(255, 255, 255, 0.8) 27%,
	  rgba(255, 255, 255, 0) 27.1%,
	  rgba(255, 255, 255, 0) 100%
	);
	background: linear-gradient(
	  130deg,
	  rgba(255, 255, 255, 0.8) 44%,
	  rgba(255, 255, 255, 0.8) 27%,
	  rgba(255, 255, 255, 0) 27.1%,
	  rgba(255, 255, 255, 0) 100%
	);
}

@media (min-width: 576px) {
}

@media (min-width: 768px) {
  #why-choose-evs {
    padding: 0rem 2rem;
  }

  .big-promise-caption {
    padding: 3rem 2rem;
  }

  .gradient-top-left {
    background: -moz-linear-gradient(
      -40deg,
      rgba(255, 255, 255, 0.9) 37%,
      rgba(255, 255, 255, 0.8) 27%,
      rgba(255, 255, 255, 0) 27.1%,
      rgba(255, 255, 255, 0) 100%
    );
    background: -webkit-linear-gradient(
      -40deg,
      rgba(255, 255, 255, 0.8) 37%,
      rgba(255, 255, 255, 0.8) 27%,
      rgba(255, 255, 255, 0) 27.1%,
      rgba(255, 255, 255, 0) 100%
    );
    background: linear-gradient(
      130deg,
      rgba(255, 255, 255, 0.8) 37%,
      rgba(255, 255, 255, 0.8) 27%,
      rgba(255, 255, 255, 0) 27.1%,
      rgba(255, 255, 255, 0) 100%
    );
  }
}

@media (min-width: 992px) {
  #why-choose-evs {
    padding: 0rem 3rem;
  }

  .gradient-top-left {
    background: -moz-linear-gradient(
      -40deg,
      rgba(255, 255, 255, 0.9) 30.5%,
      rgba(255, 255, 255, 0.8) 27%,
      rgba(255, 255, 255, 0) 27.1%,
      rgba(255, 255, 255, 0) 100%
    );
    background: -webkit-linear-gradient(
      -40deg,
      rgba(255, 255, 255, 0.8) 30.5%,
      rgba(255, 255, 255, 0.8) 27%,
      rgba(255, 255, 255, 0) 27.1%,
      rgba(255, 255, 255, 0) 100%
    );
    background: linear-gradient(
      130deg,
      rgba(255, 255, 255, 0.8) 30.5%,
      rgba(255, 255, 255, 0.8) 27%,
      rgba(255, 255, 255, 0) 27.1%,
      rgba(255, 255, 255, 0) 100%
    );
  }
}

@media (min-width: 1200px) {
  .gradient-top-left {
    background: -moz-linear-gradient(
      -40deg,
      rgba(255, 255, 255, 0.9) 0%,
      rgba(255, 255, 255, 0.8) 27%,
      rgba(255, 255, 255, 0) 27.1%,
      rgba(255, 255, 255, 0) 100%
    );
    background: -webkit-linear-gradient(
      -40deg,
      rgba(255, 255, 255, 0.8) 0%,
      rgba(255, 255, 255, 0.8) 27%,
      rgba(255, 255, 255, 0) 27.1%,
      rgba(255, 255, 255, 0) 100%
    );
    background: linear-gradient(
      130deg,
      rgba(255, 255, 255, 0.8) 0%,
      rgba(255, 255, 255, 0.8) 27%,
      rgba(255, 255, 255, 0) 27.1%,
      rgba(255, 255, 255, 0) 100%
    );
  }
}
