@import '../../scss/client_master.scss';

$RateAdvisorChart-border: 1px solid #dadada !default;
$RateAdvisorChart-border-top: null !default;
$RateAdvisorChart-border-bottom: null !default;
$RateAdvisorChart-border-left: null !default;
$RateAdvisorChart-border-right: null !default;
$RateAdvisorChart-body-padding: 3em 2em !default;
$RateAdvisorChart-box-shadow: none !default;
$RateAdvisorChart-title-text-align: left !default;
$RateAdvisorChart-title-text-transform: none !default;
$RateAdvisorChart-title-font-size: 18px !default;
$RateAdvisorChart-background-color: #fff !default;
$RateAdvisorChart-title-font-family: Open Sans, Helvetica, sans-serif;
$RateAdvisorChart-border-radius: null !default;

.RateAdvisorChart {
  position: sticky;
  top: 5px;
  max-height: 100vh;
  overflow-y: auto;

  background-color: $RateAdvisorChart-background-color;
  border: $RateAdvisorChart-border;
  border-top: $RateAdvisorChart-border-top;
  border-bottom: $RateAdvisorChart-border-bottom;
  border-left: $RateAdvisorChart-border-left;
  border-right: $RateAdvisorChart-border-right;
  border-radius: $RateAdvisorChart-border-radius;


  box-shadow: $RateAdvisorChart-box-shadow;

  .title {
    text-align: $RateAdvisorChart-title-text-align;
    text-transform: $RateAdvisorChart-title-text-transform;
    font-size: $RateAdvisorChart-title-font-size;
    font-family: $RateAdvisorChart-title-font-family;
  }

  .chart-body {
    padding: $RateAdvisorChart-body-padding;
  }

  // Bootstrap's toggles and checkboxes line up poorly with text smaller than
  // 14px
  label {
    font-size: 14px;
  }
}
