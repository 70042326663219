@import "src/client_customizations/scss/client_master.scss";

.link-card-container {
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	justify-content: space-between;
	align-items: stretch;
	max-width: 400px;
	margin: 0 auto;
}
.link-card {
	display: flex;
	text-align: center;
	flex-shrink: 1;
	flex-basis: 110px;
	cursor: pointer;
	img {
		width: 45px;
	}
	p {
		font-weight: 700;
		margin-top: 0.5rem;
		margin-bottom: 0;
	}
	&:hover {
		p {
			text-decoration: none;
		}
	}
	.evc-card {
		background-color: $LinkCard-evc-card-background-color;
		border: 1px solid $LinkCard-evc-card-background-color;
		box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.25);
		padding: 1rem;
		border-radius: 4px;
		p {
			font-size: 0.8rem;
		}
		&:hover {
			box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.25);
		}
	}
}
@media (min-width: 576px) {
	.link-card-container {
		max-width: 500px;
	}
	.link-card {
		flex-basis: 140px;
		img {
			width: 55px;
		}
		.evc-card {
			p {
				font-size: 0.85rem;
			}
		}
	}
}
@media (min-width: 768px) {
	.link-card {
		flex-basis: 140px;
	}
}
