@import '../../scss/client_master.scss';

$RateComparisonBanner-background-color: #000000 !default;
$RateComparisonBanner-color: #ffffff !default;
$RateComparisonBanner-font-size: 16px !default;
$RateComparisonBanner-highlight-text-color: #FBBB36 !default;
$RateComparisonBanner-border-radius: null !default;

.RateComparisonBanner {
  text-align: center;
  background-color: $RateComparisonBanner-background-color;
  padding: 0.5em;
  color: $RateComparisonBanner-color;
  font-size: $RateComparisonBanner-font-size;
  border-radius: $RateComparisonBanner-border-radius;

  .highlight {
    color: $RateComparisonBanner-highlight-text-color;
  }
}
