$RateOptionsWizard-btn-color: #222222 !default;
$RateOptionsWizard-btn-background-color: #e5e5e5 !default;
$RateOptionsWizard-btn-active-color: #ffffff !default;
$RateOptionsWizard-btn-active-background-color: #222222 !default;

.RateOptionsWizard {
  .modal-body {
    min-height: 300px;
  }

  .modal-footer {
    @media (max-width: 767px) {
      flex-wrap: wrap;
    }

    .btn {
      min-width: 200px;
      @media (max-width: 767px) {
        display: block;
        margin-bottom: 0.5em;
      }
    }
  }

  label {
    text-transform: uppercase;
  }

  .ButtonGroup {
    .btn {
      background-color: $RateOptionsWizard-btn-background-color;
      color: $RateOptionsWizard-btn-color;
      border-radius: 0;
      height: 80px;

      &.active {
        background-color: $RateOptionsWizard-btn-active-background-color;
        color: $RateOptionsWizard-btn-active-color;
      }
    }
  }
}
